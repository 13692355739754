import React from "react";
import { FaFacebookF, FaTwitter,FaInstagram, FaPinterestP } from "react-icons/fa";
export default function Footer(props){
    return (
       <footer>
            <div className="cm-page-center footer-link-column">
                <div className="cm-flex cm-flex-wrap footer">
                    <div className="cm-wd-50 cm-mob-wd-full footer-col">
                        <h3>About Us</h3>
                        <div className="line-bar"></div>
                        <p>AtGuestHubse, we're more than just a platform for Guesthubs; we're a thriving community of passionate writers, thought leaders, and avid readers who believe in the power of sharing knowledge and ideas. Our journey began with a simple vision: to create a space where diverse voices can come together to inspire, educate, and connect.</p>
                    <div className="cm-flex social-icon">
                        <ul>
                        <li className="social-icon-list"><FaFacebookF /></li>
                        <li className="social-icon-list"><FaTwitter /></li>
                        <li className="social-icon-list"><FaInstagram /></li>
                        <li className="social-icon-list"><FaPinterestP /></li>
                        </ul>
                    </div>
                    </div>
                    <div className="cm-wd-50 cm-mob-wd-full footer-col">
                        <h3>Instragram Follow</h3>
                        <div className="line-bar"></div> 
                    </div>
                </div>
            </div>
            <div className="cm-txt-center footer-copywite">
                <p>Copyright © 2019 All Rights Reserved.</p>
            </div>
       </footer>
    );
}