import React, { useState } from 'react';
import Navbar from '../../component/Navbar';
import Footer from '../../component/Footer';
import { base_url } from '../../utility/utility';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../../css/business.css';
import { Helmet } from 'react-helmet';
import { ImOffice } from 'react-icons/im';
import { FaUsers } from 'react-icons/fa';
import {  MdLocationOn, MdPunchClock} from 'react-icons/md';
import {useMutation, useQuery } from '@tanstack/react-query';
import Loading from '../../component/Loading';
import Register from './component/Register';

 const  BusinessList = () => {
    const [page, setPage] = useState(1);
    const [popup, setPopup] = useState(false);
    const [opentype, setOpentype] = useState('register');
    const businessCate = async() => {
        const category = base_url+'/api/getbusiness-category';
        const respond = await axios.get(category);
        return respond.data;
    }

    const businessData = async() => {
        const url = base_url+'/api/get-business-record?page='+page;
        const respond = await axios.get(url);
        return respond.data;
    }

    const  {data: busCat} = useQuery({queryKey : ['bussinessCategory'], queryFn : businessCate});
    const  {isLoading, data: busData} = useQuery({queryKey : ['bussinessData', page], queryFn : businessData});

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = new FormData(e.target);
        // useMutation({})
    }

    return (
        <>
        <Helmet>
            <title>Business Listing | Guesthub site free use all users</title>
        </Helmet>
        <Navbar title = "Logo" />
        <div className='cm-position-relative herosection'>
            <img src='images/photo-galleery1-bg.jpg' alt='' />
            <div className='cm-position-absolute hero-section-inner'>
                <h3>Business Listing</h3>
            </div>
        </div>
        <section className=''>
            <div className='cm-page-center'>
                <div className='searchbusiness'>
                    <h3 className='cm-wd-100'>Exploring Excellence in Business</h3>
                    <form method="post" onSubmit={handleSubmit}>
                        <div className='searchform'>
                            <input type='text' name='search'  placeholder='Search company, keyword'/>
                            <input type='text' name='location' placeholder='location'/>
                            <select name='category'>
                                <option value="">--Select Category--</option>
                                {busCat?.map((index, value) => {
                                    return <option value={index.name}>{index.name}</option>
                                })}
                            </select>
                            <input type='submit' name='go' value='Search' />
                    </div>
                    </form>
                </div>
            </div>
        </section>
        <section className='cm-section cm-padt-150'>
            <div className='cm-page-center'>
                <div className='cm-flex-type-2'>
                    <div className='business-listing cm-wd-70'>
                        {isLoading ? <Loading /> : "" }
                        {busData?.data?.map((row) => (
                            <div className='business-box'>
                                <div className='cm-flex'>
                                    <div className='cm-wd-20 compnayLogo'>
                                        <Link to={`/business-listing/${row.slug}`} key={row.id}>
                                        <img src={row.compLogo} /> </Link>
                                    </div>
                                    <div className='cm-wd-70'>
                                        <h3>{row.compName}</h3>
                                        <div className='cm-flex-type-1 detailbusinesslist'>
                                            <div>
                                                <p><ImOffice /> {row.compAddress}</p>
                                                <p><FaUsers /> {row.websiteUrl}</p>
                                            </div>
                                            <div>
                                                <p><MdLocationOn /> {row.compAddress}</p>
                                                <p><MdPunchClock /> {row.websiteUrl}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='short-desc-business'>
                                    <p>{row.short_desc}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='cm-wd-30 stickybusiness'>
                        <div className='cm-text-center'>
                            <div className='cm-wd-80 freeregisterbusiness'>
                                <h3>Free Registration, Limitless Possibilities</h3>
                                <img src='/images/busines/1.jpg' />
                                <p>Welcome to Guesthub – Your gateway to endless opportunities. Join us today with a simple, hassle-free registration process, and explore a world of benefits, connections, and growth—all at no cost. Start your journey now!</p>
                                <button class="cm-prime-btn" onClick={() => setPopup(true)}> Register </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        {popup? <Register setPopup={setPopup} setOpentype={setOpentype} /> :""}
        
        <Footer />
        </>
    );
}

export default BusinessList;