import React from "react";
import { FiX } from "react-icons/fi";

const Forgot = ({setPopup, setOpentype}) => {
    return (
    <div className="modal">
      <div className='modal-overlay'></div>
      <div className="modal-content">
      <span onClick={() => setPopup(false)} className="close-modal"><FiX /></span>
        <div className="modal-head"></div>
        <div className="modal-body">
          <h3 className="cm-text-center text-white">Forgot Password</h3>
          <form>
            <div className="form-group">
              <input type="text" name="username" className="form-control"  placeholder="Enter username"/>
            </div>
            <div className="form-group">
              <input type="password" className="form-control" placeholder="Enter password" name="password" />
            </div>
            <div className="form-group">
              <input type="submit" name="Login" className="cm-prime-btn" value="Login" />
            </div>
          </form>
          <div className="cm-flex-type-1">
            <span className="cursor text-white" onClick={() => setOpentype('register')}>Sign Up ?</span>
            <span className="cursor text-white" onClick={() => setOpentype('login')}>Login ?</span>
          </div>
        </div>
        <div className="modal-footer"></div>
      </div>
    </div>
    );
}

export default Forgot;