import React from 'react';
import Footergallery from '../component/Footergallery';
import HomeSlider from '../component/HomeSlider';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import Category from '../component/Category';
import {Helmet} from "react-helmet";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import TrendingBlog from './front/component/TrendingBlog';
import RecentBlog from './front/component/RecentBlog';
import WeekendBlog from './front/component/WeekendBlog';

const Home = () => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home | Guesthub site free use all users</title>
                <link rel="canonical" href="" />
            </Helmet>
            <Navbar title="Logo" />
            <HomeSlider className="body-parts"/>

            {/* Trending Blog */}
            <TrendingBlog />
            {/* Recent News  */}

            {/* Category Start */}
            <section className="cm-section">
                <div className="cm-page-center">
                    <h1 className="cm-heading cm-mb-30">Our Category</h1>
                    <div className="">
                        <Category />
                    </div>
                </div>
            </section>
            {/* Category End */}

            <RecentBlog />

            <div className='cm-section'>
                <div className='cm-position-relative'>
                    <div className="banner-recent">
                        <div className="cm-page-center">
                            <div className='cm-flex-type-1 banner-image-content'>
                                <a href="/#" className='banner-discount'>GET 50% DISCOUNT OFFERS <br></br>HOW TO USE? JUST SHOW US THIS SCREEN</a>
                                <a href="/#" className='cm-prime-btn'>Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Weeked Blog */}

            <WeekendBlog />

            <div className='cm-section'>
                <div className='cm-position-relative'>
                    <div className="banner-recent">
                        <div className="cm-page-center">
                            <div className='cm-flex-type-1 banner-image-content'>
                                <a href="/#" className='banner-discount'>GET 50% DISCOUNT OFFERS <br></br>HOW TO USE? JUST SHOW US THIS SCREEN</a>
                                <a href="/#" className='cm-prime-btn'>Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footergallery />
            <section className='cm-section newslatter-section'>
                <div className='cm-page-center'>
                    <div className='cm-text-center'>
                        <h2>Subscribe to Newsletter!</h2>
                        <div className='bar'></div>
                        <div className='cm-wd-50 cm-wd-full cm-mob-wd-full cm-mlr-auto cm-mb-30'>
                            <p>Lorem ipsum dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culp a qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                        <form>
                            <input type="text" className='cm-wd-full cm-mob-wd-70' placeholder='Enter your email' name='email' />
                            <input type="submit" value="Subscribe" />
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    );
}

export default Home;