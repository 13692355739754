import  React from 'react';
import { useParams, Link } from "react-router-dom";
import axios from 'axios';
import { FaUserAlt, FaCalendarAlt, FaRegComment } from "react-icons/fa";
import { format, parseISO } from 'date-fns'
import Pagination from '../../component/Pagination';
import Navbar from '../../component/Navbar';
import Footer from '../../component/Footer';

import "../../css/post-list.css";
import Helmet from 'react-helmet';
import { base_url } from '../../utility/utility';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../component/Loading';

const Searchpage = () => {
    
    let params = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    let page = queryParams.get("search");

    const getSearchVal = async() => {
        const url = base_url+'/api/search?search='+page;
        const response = await axios.get(url);
        return response.data;
    }

    const {data, isLoading} = useQuery({queryKey : ['search', page], queryFn: getSearchVal});
    // business-search
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Guesthub site free use all users</title>
            <link rel="canonical" href="" />
        </Helmet>
        <Navbar title='Logo'/>
        <div className='cm-position-relative herosection'>
            <img src='/images/photo-galleery1-bg.jpg' alt='' />
            <div className='cm-position-absolute hero-section-inner'>
                <h3>{page}</h3>
            </div>
        </div>

        <div className='cm-page-center'>
            <div className='ptb-70'>
            <h1>{page}</h1>
            <div className='cm-flex-type-2 cm-flex-wrap'>
                {isLoading ? <Loading /> :""}
                {data?.data?.map((row) => {
                        return (
                            <div className="cm-trading-box" key={row.id}>
                                <div className="cm-trading-content">
                                <Link to={'/post/'+row.slug}>
                                    <img src={row.post_image}  alt="Test"/>
                                </Link>
                                <div>
                                    <Link to={'/post/'+row.slug}>{row.name}</Link>
                                    <div className='cm-flex-type-1 cm-mt-20'>
                                        <span><FaUserAlt /> {row.get_user.fname} </span>
                                        <span><FaCalendarAlt /> {format(parseISO(row.created_at), 'dd-mm-yyyy')}</span>
                                        <span><FaRegComment /> 236</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        );
                        })
                    }
                <div>{data?.data.length==0? <h3 className="cm-center">Ops! It seems like the content you're looking for is not available.</h3> : ""}</div>
            </div>

            <div className='paginationlist'>
                <Pagination  links={data?.links} currentPage={data?.current_page} category={params.cateSlug}/> 
            </div>
            </div>
        </div>
        <Footer />
        </>
    );
}

export default Searchpage;