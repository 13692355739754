import React from "react";
import { FaRegClock } from "react-icons/fa";


export const BusinessTime = ({business}) => {
    // console.log("Business", business);
    return (
        <>
        <p><FaRegClock /> <span> Monday : </span> <span>{business.monday.From.Hour} : {business.monday.From.Minute} {business.monday.From.AmPm}  To {business.monday.To.Hour} : {business.monday.To.Minute} {business.monday.To.AmPm}</span></p>
                    {/* Tuesday */}
        <p><FaRegClock />  <span>Tuesday : </span>  <span>{business.tuesday.From.Hour} : {business.tuesday.From.Minute} {business.tuesday.From.AmPm}  To {business.tuesday.To.Hour} : {business.tuesday.To.Minute} {business.tuesday.To.AmPm}</span> </p>
        <p><FaRegClock />  <span>Wednesday : </span> <span>{business.wednesday.From.Hour} : {business.wednesday.From.Minute} {business.wednesday.From.AmPm}  To {business.wednesday.To.Hour} : {business.wednesday.To.Minute} {business.wednesday.To.AmPm}</span> </p>
        <p><FaRegClock />  <span>Thursday : </span> <span>{business.thursday.From.Hour} : {business.thursday.From.Minute} {business.thursday.From.AmPm}  To {business.thursday.To.Hour} : {business.thursday.To.Minute} {business.thursday.To.AmPm}</span> </p>
        <p><FaRegClock />  <span>Friday : </span> <span> {business.friday.From.Hour} : {business.friday.From.Minute} {business.friday.From.AmPm}  To {business.friday.To.Hour} : {business.friday.To.Minute} {business.friday.To.AmPm}</span> </p>
        <p><FaRegClock />  <span>Saturday : </span> <span>{business.saturday.From.Hour} : {business.saturday.From.Minute} {business.saturday.From.AmPm}  To {business.saturday.To.Hour} : {business.saturday.To.Minute} {business.saturday.To.AmPm}</span> </p>
        <p><FaRegClock />  <span>Sunday : </span> <span>{business.sunday.From.Hour} : {business.sunday.From.Minute} {business.sunday.From.AmPm}  To {business.sunday.To.Hour} : {business.sunday.To.Minute} {business.sunday.To.AmPm}</span> </p>
        </>
    );
}