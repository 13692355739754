import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import '../../css/dashboard.css';
import UserSidebar from '../../component/UserSidebar';
import { base_url } from "../../utility/utility";
import Loading from "../../component/Loading";
import Sidebar from "../../component/Sidebar";
import { useQuery } from "@tanstack/react-query";


const Dashboard = () => {
    let userinfo =  localStorage.getItem('userinfo');
    var token = JSON.parse(localStorage.getItem('token_user'));
    const getCountDash = async() => {
        const respond = await axios.get(base_url+'/api/getuserReport',{
            headers: {
                "Authorization" : `Bearer ${token}`
            }
        });
        return respond.data;
    }

    const {data, isLoading} = useQuery({queryKey: ['dashboard'], queryFn: getCountDash});

    return (
        <div className="dashboard cm-flex-type-1">
            <Sidebar />
            <div className="cm-wd-80 cm-mlr-auto">
                <div className="boday-dashboard">
                    {isLoading ? <div>Loading ...</div>:""}
                    <div>
                        <h1>Welcome to my dashboard</h1>
                        <div className="cm-flex-type-1">
                            <div className="box-dash-measure cm-bg-green text-white">
                                Total Post
                                <p>{data?.total}</p>
                            </div>
                            <div className="box-dash-measure cm-bg-red text-white">
                                Pending Post
                                <p>{data?.pending}</p>
                            </div>
                            <div className="box-dash-measure cm-bg-red text-white">
                                Aprove Post
                                <p>{data?.approved}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;