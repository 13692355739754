import './App.css';
import './Innerpage.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/front/About';
import Contact from './pages/front/Contact';
import PostDetail from './pages/PostDetail';
import CatePage from "./pages/front/CatePage";
import Myaccount from './pages/Myaccount';
import Dashboard from './pages/back/Dashboard';
import AddPost from './pages/Add-post';
import Profile from './pages/Profile';
import ListPost from './pages/List-post';
import Logout from './component/Logout';
import Editpost from './pages/Edit-post';
import BusinessList from './pages/front/BusinessList';
import BusinessListmanage from './pages/back/BusinessListmanage';

// User Dashboard 
// import ManageBusiness from './pages/Businesslist-user';
import AddBusiness from './pages/back/AddBusiness';
import PostGuidlines  from './pages/front/PostGuidlines';


import { BusinessDetail } from './pages/front/BusinessDetail';
import Searchpage from './pages/front/Searchpage';
import './css/responsive.css';

function App() {
  
  return (
    <>
    <Router>
      <Routes>
          <Route exact  path='/'  element={<Home />} />
          <Route exact  path='/about'  element={<About />} />
          <Route exact  path='/post/:postDetail'  element={<PostDetail />} />
          <Route exact  path='/category/:cateSlug'  element={<CatePage/>} />
          <Route exact  path='/category/:cateSlug/page/:pageNumber'  element={<CatePage/>} />
          <Route exact  path='/edit-post/:postid'  element={<Editpost/>} />
          <Route exact  path='/contact-us'  element={<Contact />} />
          <Route exact  path='/post-guidlines'  element={<PostGuidlines />} />
          <Route exact  path='/myaccount'  element={<Myaccount />} />
          <Route exact  path='/dashboard'  element={<Dashboard />} />
          <Route exact  path='/add-post'  element={<AddPost />} />
          <Route exact  path='/list-post'  element={<ListPost />} />
          <Route exact  path='/profile'  element={<Profile />} />
          <Route exact  path='/business-listing'  element={<BusinessList />} />
          <Route exact  path='/business-listing/:slugBusiness'  element={<BusinessDetail />} />
          <Route exact  path='/manage-business'  element={<BusinessListmanage />} />
          <Route exact  path='/add-business-list'  element={<AddBusiness />} />
          <Route exact  path='/search'  element={<Searchpage />} />
          <Route exact  path='/logout'  element={<Logout />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;