import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "../css/slider.css";
import "../App.css";

// import required modules
import { Navigation } from "swiper";

export default function App() {
  return (
    <>
      <div className="homebannerSlider">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide>
            <div className="cm-position-absolute banner-slider-content">
              <h2>Welcome to Free Guesthub site for you</h2>
              <p>Submit Top Articles and Blogs on Best Free Article submission site Guest Node.
  Drive Users to Your Website</p>
              <a href="/#" className="cm-prime-btn">Learn More</a>
            </div>
            <img src="./images/banner1.jpg" alt="yogesh"/>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cm-position-absolute banner-slider-content">
              <h2>Welcome to Free Guesthub site for you</h2>
              <p>Submit Top Articles and Blogs on Best Free Article submission site Guest Node.
  Drive Users to Your Website</p>
              <a href="/#" className="cm-prime-btn">Learn More</a>
            </div>
            <img src="./images/banner2.jpg" alt="yogesh"/>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cm-position-absolute banner-slider-content">
              <h2>Welcome to Free Guesthub site for you</h2>
              <p>Submit Top Articles and Blogs on Best Free Article submission site Guest Node.
  Drive Users to Your Website</p>
              <a href="/#" className="cm-prime-btn">Learn More</a>
            </div>
            <img src="./images/banner3.jpg" alt="yogesh"/>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cm-position-absolute banner-slider-content">
              <h2>Welcome to Aspan Amazing Blog</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud commodo consequat.</p>
              <a href="/#" className="cm-prime-btn">Learn More</a>
            </div>
            <img src="./images/banner4.jpg" alt="yogesh"/>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
