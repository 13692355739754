import React, {useState } from "react";
import { FaFacebookF,FaInstagram,FaTwitter,FaPinterestP } from "react-icons/fa";
import '../css/comment.css';
import axios from "axios";
import { base_url } from "../utility/utility";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import React  from 'react';
import Moment from 'react-moment';


const CommentPost = (comment) => {
    const [comments, setComments] = useState({});
    const getcomments = comment?.postDetail?.get_comments;

    let userinfo = localStorage.getItem('userinfo');
    var token = JSON.parse(localStorage.getItem('token_user'));
    let datauser = JSON.parse(userinfo);
    const formSubmitComment = async(e) => {
        e.preventDefault();

        if(comments['comment']==undefined || comments['comment']==""){
            toast.error("please writes your comment");
            return false;
        }

        const data = await axios.post(base_url+'/api/comments', comments);
        if(data.data.status==='1'){
            console.log("success", data);
            setComments({'comment' : '','user_id' : 0, 'fname' : '', 'lname' : '', 'email':''});
            toast.success("Submit your comment successfully!!!");
        }
        
    }

    const handlechange = (e) => {
        setComments(values => ({...values, ['post_id']: comment?.postDetail?.id}));
        if(checkAuthlogin()){
            let userid = checkAuthlogin();
            setComments(values => ({...values, ['user_id']: userid.id}));
            setComments(values => ({...values, ['fname']: userid.fname}));
            setComments(values => ({...values, ['lname']: userid.lname}));
            setComments(values => ({...values, ['email']: userid.email}));
        }else{
            setComments(values => ({...values, ['user_id']: 0}));
        }
        setComments(values => ({...values, [e.target.name]: e.target.value}))
    }

    function checkAuthlogin(){
        let userinfo  = localStorage.getItem('userinfo');
        let userinfget = JSON.parse(userinfo);
        if(userinfget!==null){
            return userinfget;
        }
        return false;
    } 


    const commentFormUserlogin = () => {
        if(!checkAuthlogin()){
            return (
                <>
                    <div className="cm-flex-type-2 comment-post">
                        <div className="form-group">
                            <label>First Name</label>
                            <input type="text" name="fname" value={comments?.fname} onChange={handlechange} className="form-control" placeholder="" />
                        </div>
                        <div className="form-group">
                            <label>Last Name</label>
                            <input type="text" name="lname" value={comments?.lname} onChange={handlechange} className="form-control" placeholder="" />
                        </div>
                    </div>
                    <div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="text" name="email" value={comments?.email} onChange={handlechange} className="form-control" placeholder="" />
                        </div>
                    </div>
                </>
            )
        }

        return <div></div>
        
    }
    

    return (
        <div>
            {/* <button onClick={notify}>Notify!</button> */}
            <ToastContainer  position="bottom-right"/>
            <div className="author-section">
                <div className="author-image"><img src={comment?.postDetail!=='' ? comment?.postDetail?.get_user?.profile_img : ''}  style={{width:'100px'}}/></div>
                <div className="author-biography">
                    <h3>{comment?.postDetail!=='' ? comment?.postDetail?.get_user?.fname.toUpperCase() : ''}</h3>
                    <p>{comment?.postDetail?.get_user?.user_bio}</p>
                    <ul>
                        <li><FaFacebookF /></li>
                        <li><FaInstagram /></li>
                        <li><FaTwitter /></li>
                        <li><FaPinterestP /></li>
                    </ul>
                </div>
            </div>
            <div className="comments-area">
                <div className="comments-title"></div>
                <div className="comments-list">
                    <ol>
                        {getcomments?.map((row, index) => {
                            let date = new Date(row.created_at);
                            return(
                                <li id="comment-21" className="comment byuser comment-author-admin bypostauthor even thread-even depth-1">
                                    <article id="div-comment-21" class="comment-body">
                                        <div className="comment-meta">
                                        <div className="comment-author vcard">
                                            <img alt="" src="http://localhost:8000/profile_image/thumbnail.jpg" className="avatar avatar-32 photo" height="32" width="32" />						
                                            <b className="fn">
                                                <a href="https://travelify.life" className="url" rel="ugc">{row.fname}</a>
                                                <div className="comment-metadata">
                                                    <time> <Moment format="DD/MM/YYYY HH:mm">{row.created_at}</Moment></time>
                                                </div>
                                            </b> 
                                            
                                        </div>
                                        
                                        </div>
                                        <div className="comment-content">
                                            <p>{row.comment}</p>
                                        </div>
                                        <div className="reply"><a rel="nofollow" className="comment-reply-link" href="https://travelify.life/planning-your-india-trip/?replytocom=21#respond" data-commentid="21" data-postid="112" data-belowelement="div-comment-21" data-respondelement="respond" data-replyto="Reply to admin" aria-label="Reply to admin">Reply</a></div>		
                                     </article>
                                </li>
                            )
                        })}


                        
                    </ol>
                </div>
            </div>
            <h3>Leave a Reply</h3>
            <div className="">
                <form className="" onSubmit={formSubmitComment}>
                    {commentFormUserlogin()}
                    <div>
                        <div className="form-group">
                            <textarea type="text" name="comment" onChange={handlechange} value={comments?.comment} className="form-control" rows={8} placeholder="Write here... " />
                        </div>
                    </div>
                    <div>
                        <div className="form-group">
                            <input type="submit" className="cm-prime-btn active" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CommentPost;