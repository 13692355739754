import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link , useNavigate} from "react-router-dom";
import UserSidebar from '../../component/UserSidebar';
import {base_url} from '../../utility/utility';

import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';



// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loading from "../../component/Loading";
import { toast } from "react-toastify";
import { RxRowSpacing } from "react-icons/rx";
// component/Loading";
const AddBusiness = () =>{
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');

    const [successMesssage, setsuccessMessage] = useState();
    const [image, setImage] = useState('');
    const [longdesc, setLongDesc] = useState('');
    const [isLoading, setisLoading] = useState(true);
    const [businessCategory, setbusinessCategory] = useState();



    const navigate = useNavigate();
    let userinfo = localStorage.getItem('userinfo');
    var token = JSON.parse(localStorage.getItem('token_user'));
    let datauser = JSON.parse(userinfo);

    // console.log("Token ::", token);

    const [addForm, setAddForm] = useState({});

    const clearformData = () => {
        setAddForm({});

        setLongDesc('');

    }

    const handleSubmit = (e) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        let formdata = new FormData(e.currentTarget);
        axios.post(
            base_url+'/api/add-business', 
            formdata, 
            {
                headers: {
                    "Authorization" : `Bearer ${token}`,
                    "Content-Type": "multipart/form-data" 
                }
            }).then((response) => {

                if(response.data.status==='1'){
                    // console.log('response Submit Data', response.data);
                    document.getElementById("add-post-form").reset();
                    clearformData();
                    setsuccessMessage(response.data.msg);
                }
                else if(response.data.msg==='Form Error'){
                    // console.log("error Message", response.data);
                    response.data?.data?.forEach(rows => {
                        toast.error(rows, {
                                position: "top-right",
                                autoClose: false,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                    });
                }
                
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if(userinfo===null){
            navigate('/')
        }
        setisLoading(false);
        const category = base_url+'/api/getbusiness-category';
        axios.get(category).then((response) => {
            setbusinessCategory(response.data);
        });
        
    }, []);


    const handlechange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setAddForm(values => ({...values, [name]: value}));
        if(name==='name'){
           let newval =  value.toLowerCase()
             .replace(/ /g, '-')
             .replace(/[^\w-]+/g, '');
             setAddForm(values => ({...values, ['slug']: newval}));
        }

    }

    console.log("Form Value", addForm);

    const handleImage = (e) => {
        console.log('Files', e.target.files);
        setImage(e.target.files[0]);
    }

    let showContent;
    if(isLoading){
        showContent = <Loading />
    }else{
        showContent = <div className="cm-card">
            <form onSubmit={handleSubmit} id="add-post-form">
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-50">
                        <label>Company Name</label>
                        <input type="text" className="form-control"  value={addForm.compName} onChange={handlechange} name="compName" placeholder="Enter Title" />
                    </div>
                    <div className="form-group cm-wd-50">
                        <label>Helpdesk Name</label>
                        <input type="text" className="form-control"  value={addForm.helpDesk} onChange={handlechange} name="helpDesk" placeholder="Helpdesk Name" />
                    </div>
                </div>
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-50">
                        <label>Business Category</label>
                        <select onChange={handlechange} name="businessCategory" className="form-control">
                            <option value="">--Select Category--</option>
                            {businessCategory && businessCategory.map((index, value) => {
                                return <option value={index.name}>{index.name}</option>
                            })}
                        </select>
                    </div>
                    {/* <div className="form-group cm-wd-50">
                        <label>Business Category</label>
                        <input type="text" className="form-control"  value={addForm.compName} onChange={handlechange} name="compName" placeholder="Enter Title" />
                    </div> */}
                </div>
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-40">
                        <label>Company Address1</label>
                        <input type="text" onChange={handlechange} name="compAddress" value={addForm.compAddress} placeholder="Company Address" className="form-control" />
                    </div>
                    <div className="form-group cm-wd-40">
                        <label>Company Address2</label>
                        <input type="text" onChange={handlechange} name="compAddress" value={addForm.compAddress} placeholder="Company Address" className="form-control" />
                    </div>
                    <div className="form-group cm-wd-40">
                        <label>Company Found On</label>
                        <input type="date" className="form-control"  value={addForm.compFoundOn} onChange={handlechange} name="compFoundOn" placeholder="Company Found On" />
                    </div>
                </div>
                <div className="cm-flex-type-1">
                <div className="form-group cm-wd-50">
                <CountryDropdown
                    value={country}
                    name="country"
                    className="form-control"

                    onChange={(val) => setCountry(val)} />
                    </div>
                    <div className="form-group cm-wd-50">
                    <RegionDropdown
                        country={country}
                        value={region}
                        name="state"
                        className="form-control"
                        onChange={(val) => setRegion(val)} />
                        </div>
                    {/* <div className="form-group cm-wd-50">
                        <label>Select Country</label>
                        <select onChange={handlechange} name="country"  className="form-control">
                            <option value="">--Select Country--</option>
                            <option value="IN">India</option>
                        </select>
                    </div>
                    <div className="form-group cm-wd-50">
                        <label>Select State</label>
                        <select onChange={handlechange} name="state"  className="form-control">
                            <option value="">--Select State--</option>
                            <option>Delhi</option>
                        </select>
                    </div> */}
                </div>
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-100">
                        <label>Business Hourse</label>
                        <div className="cm-flex-type-1 bussiness-hours">
                            <span className="weekname">Monday</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="mondayFromHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="mondayFromMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="mondayFromAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <span>To</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="mondayToHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="mondayToMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="mondayToAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <input type="checkbox" onChange={handlechange} name="mondayClose" />
                            <span>Close ?</span>
                        </div>
                        <div className="cm-flex-type-1 bussiness-hours">
                            <span>Tuesday</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="tuesdayFromHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="tuesdayFromMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="tuesdayFromAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <span>To</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="tuesdayToHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="tuesdayToMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="tuesdayToAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <input type="checkbox" onChange={handlechange} name="tuesdayClose"/>
                            <span>Close ?</span>
                        </div>
                        <div className="cm-flex-type-1 bussiness-hours">
                            <span className="weekname">Wednesday</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="wednesdayFromHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="wednesdayFromMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="wednesdayFromAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <span>To</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="wednesdayToHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="wednesdayToMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="wednesdayToAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <input type="checkbox" onChange={handlechange} name="wednesdayClose"/>
                            <span>Close ?</span>
                        </div>
                        <div className="cm-flex-type-1 bussiness-hours">
                            <span className="weekname">Thursday</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="thursdayFromHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="thursdayFromMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="thursdayFromAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <span>To</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="thursdayToHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="thursdayToMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="thursdayToAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <input type="checkbox" onChange={handlechange} name="thursdayClose"/>
                            <span>Close ?</span>
                        </div>
                        <div className="cm-flex-type-1 bussiness-hours">
                            <span className="weekname">Friday</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="fridayFromHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="fridayFromMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="fridayFromAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <span>To</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="fridayToHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="fridayToMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="fridayToAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <input type="checkbox" onChange={handlechange} name="fridayClose"/>
                            <span>Close ?</span>
                        </div>
                        <div className="cm-flex-type-1 bussiness-hours">
                            <span className="weekname">Saturday</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="saturdayFromHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="saturdayFromMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="saturdayFromAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <span>To</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="saturdayToHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="saturdayToMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="saturdayToAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <input type="checkbox" onChange={handlechange} name="saturdayClose"/>
                            <span>Close ?</span>
                        </div>
                        <div className="cm-flex-type-1 bussiness-hours">
                            <span className="weekname">Sunday</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="sundayFromHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="sundayFromMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="sundayFromAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <span>To</span>
                            <div className="cm-flex-type-1">
                                <select onChange={handlechange} className="cm-wd-30" name="sundayToHour">
                                    <option>12</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="sundayToMinute">
                                    <option>00</option>
                                    <option>15</option>
                                    <option>30</option>
                                    <option>45</option>
                                </select>
                                <select onChange={handlechange} className="cm-wd-30" name="sundayToAmPm">
                                    <option>AM</option>
                                    <option>PM</option>
                                </select>
                            </div>
                            <input type="checkbox" onChange={handlechange} name="sundayClose"/>
                            <span>Close ?</span>
                        </div>
                    </div> 
                </div>
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-30">
                        <label>Company Logo</label>
                        <p>Max File Size: ( 2MB. Recommended Image Dimensions: 512x512 )</p>
                        <input type="file" name="compLogo" className="form-control" />
                    </div>
                    <div className="form-group cm-wd-30">
                        <label>No. of Employee</label>
                        <input type="text" name="noEmployee" className="form-control" />
                    </div>
                </div>
                
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-45">
                        <label>Phone Number</label>
                        <input type="text" onChange={handlechange} name="phoneNumber" placeholder="Phone Number" value={addForm.phoneNumber} className="form-control" />
                    </div>
                    <div className="form-group cm-wd-45">
                        <label>Email</label>
                        <input type="text" onChange={handlechange}  name="email" value={addForm.email} placeholder="Email" className="form-control" />
                    </div>
                </div>
               
                    <div className="form-group">
                        <label>Website URL</label>
                        <input type="text" onChange={handlechange} name="websiteUrl" value={addForm.websiteUrl} placeholder="Website Url" className="form-control" />
                    </div>
                
                <div className="form-group">
                    <label>Short Description</label>
                    <textarea className="form-control" rows={8} name="short_desc"  onChange={handlechange}  placeholder="Short Description..">{addForm.short_desc}</textarea>
                </div>
                <div className="form-group">
                    <label>Description</label>
                    {/* <CKEditor
                        editor={ ClassicEditor }
                        data={longdesc}
                        onReady={ editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setLongDesc(data);
                            console.log( { event, editor, data } );
                        } }
                        onBlur={ ( event, editor ) => {
                            console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            console.log( 'Focus.', editor );
                        } }
                        name="long_desc"
                        rows={8}
                    /> */}
                </div>
                <div className="form-group">
                    <input type="submit" className="cm-prime-btn"/>
                </div>
            </form>
        </div>
    }

    
    return(
        <div className="dashboard cm-flex-type-1">
            <div className="sidebar cm-wd-20">
                <UserSidebar />
            </div>
            <div className="cm-wd-60 cm-mlr-auto">
                <div className="boday-dashboard">
                    <h3>Add Business</h3>
                   {/* {errorMessage && errorMessage.map((row) => {
                    toast.warning(row);
                        // return (<div className="alert alert-danger" >{row} <span onClick={(e) => e.stopPropagation()}>X</span></div>);
                    })} */}
                    {(successMesssage)? <div className="alert alert-success">{successMesssage}</div>:''}
                    {showContent}
                </div>
            </div>
        </div>
    )
}

export default AddBusiness;