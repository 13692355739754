import  {useState, useEffect} from 'react';
import { useParams, Link } from "react-router-dom";
import CommentPost from './CommectPost';
import axios from 'axios';
import '../css/postdetail.css';

import { base_url,URL } from '../utility/utility';

import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import Loading from '../component/Loading';
import Moment from 'react-moment';
const PostDetail = (props) => {
    let params = useParams();
    const getDetailPost = async() => {
        const url = base_url+'/api/get-postdetail?slug='+params.postDetail;
        const respond = await axios.get(url);
        return respond.data;
    }
    
    const getRecentPost = async() => {
        const url2 = base_url+'/api/get-post?type=recent_post&post_slug='+params.postDetail;
        const respond = await axios.get(url2);
        return respond.data;
    }

    const {isLoading, data : postDetails} = useQuery({queryKey: ['getpostDetail', params], queryFn: getDetailPost});
    const {data : recent} = useQuery({queryKey: ['getrecentpost',params], queryFn: getRecentPost});
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [params])
    
    return (
        <>
            <Helmet>
                <title>{postDetails?.name}</title>
                <meta name='description' content={postDetails?.meta_desc}/>
                <meta property="og:title" content={postDetails?.name} />
                <meta property="og:description" content={postDetails?.meta_desc} />
                <meta property="og:url" content={URL+`/post/${postDetails?.slug}`} />
                <meta property="og:image" content={postDetails?.post_image} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content=""/>
                <link rel="canonical" href={URL+`/post/`+postDetails?.slug} />
                {/* Twitter Card */}
                <meta name="twitter:card" content={postDetails?.name} />
                <meta name="twitter:title" content={postDetails?.name} />
                <meta name="twitter:description" content={postDetails?.meta_desc} />
                <meta name="twitter:image" content={postDetails?.post_image} />
                <meta name="twitter:url" content={URL+`/post/${postDetails?.slug}`} />
                <meta name="twitter:site" content="@guesthubse" />
                <meta name="twitter:creator" content="" />
                <meta name="twitter:domain" content="" />
            </Helmet>
            <Navbar title="Logo"/>
            <div className='text-center hero-section-postdetail body-parts'>
                <div className='bg-post-detail cm-page-center'>
                    <h1>{postDetails?.name}</h1>
                </div>
            </div>
            <div className='cm-page-center pagepostDetail'>
                <div className='cm-flex-type-2 mob-flex-wrap'>
                    <div className='cm-wd-65 cm-wd-full cm-content-postarea'>
                        <div className='postdetailImage'>
                            <img src={postDetails?.post_image} alt='' />
                        </div>
                        <div className='dateandtimeDescrition'>
                            <div className='border'><span>Date : <time><Moment format="lll">{postDetails?.updated_at}</Moment></time> | </span> <span>Comment : {postDetails?.get_comments.length}</span> | <span>Author : {postDetails?.get_user?.fname} {postDetails?.get_user?.lname}</span></div>
                        </div>
                        <div className='long-desc'>
                            <div dangerouslySetInnerHTML={ {__html: postDetails?.long_desc} }></div>
                        </div>
                    </div>
                    <div className='cm-wd-30 cm-wd-full sticky-most-recent'>
                        <div>
                            <h3>Most Recent Post</h3>
                            <div className='listof-post'>
                               {isLoading ? <Loading /> : ""}
                                {recent?.map((row) => (
                                        <div className='cm-flex recent-most-list' key={row.id}>
                                            <div className='cm-wd-25 imagefile'>
                                                <Link to={'/post/'+row.slug}>
                                                <img src={row.post_image} className="most-recent-img" alt='' />
                                                </Link>
                                            </div>
                                            <div className='cm-wd-75 post-content'>
                                                <Link to={'/post/'+row.slug}><p>{row.name}</p></Link>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cm-wd-65 cm-wd-full'>
                    <CommentPost postDetail={postDetails}/>
                </div>
            </div>
            <Footer />
        </>
    );


}


export default PostDetail;