import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import UserSidebar from '../component/UserSidebar';
import {base_url} from '../utility/utility';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import Loading from "../component/Loading";
import Sidebar from "../component/Sidebar";


const Editpost = () =>{
    const [category, setCategory] = useState({});
    const [errorMessage, seterrorMessage] = useState();
    const [showSuccess, setshowSuccess] = useState('');
    const [image, setImage] = useState('');
    const [editimage, seteditImage] = useState('');
    const [longdesc, setLongDesc] = useState('');
    const [isLoading, setisLoading] = useState(true);
    const parms =  useParams();

    let userinfo = localStorage.getItem('userinfo');
    var token = JSON.parse(localStorage.getItem('token_user'));
    let datauser = JSON.parse(userinfo);

    const [addForm, setAddForm] = useState({
        'name':'',
        'slug' : '',
        'short_desc' : '',
        'meta_title' : '',
        'meta_desc' : '',
        'cate_id' : '',
        'image_alt' : ''
    });



    const handleSubmit = (e) => {
        e.preventDefault();
        let formdata = new FormData();
        formdata.append('name', addForm.name);
        formdata.append('slug', addForm.slug);
        formdata.append('short_desc', addForm.short_desc);
        formdata.append('long_desc', longdesc);
        formdata.append('meta_title', addForm.meta_title);
        formdata.append('meta_desc', addForm.meta_desc);
        formdata.append('cate_id', addForm.cate_id);
        formdata.append('user_id', datauser.id);
        formdata.append('post_id', addForm.post_id);
        if(image!==""){
            formdata.append('post_image', image);
        }
       
        axios.post(
            base_url+'/api/update-post', 
            formdata, 
            {
                headers: {
                    "Authorization" : `Bearer ${token}`,
                    "Content-Type": "multipart/form-data" 
                }
            }).then((response) => {
                console.log('response', response.data);
                console.log('response Submit Data', response.data);
                if(response.data.status==='1'){
                    setFormfield();
                    setshowSuccess(response.data.msg);
                }else{
                    console.log("error Message", response.data);
                    seterrorMessage(response.data.data);
                }
                
            });
    }

    const setFormfield = () => {
        setisLoading(true);
        axios.get(base_url+'/api/post-detail/'+parms.postid, {
            headers: {
            "Authorization" : `Bearer ${token}`
            }
        }).then((response) => {
            setisLoading(false);

            console.log("post Detail", response.data);
            setAddForm({
                'name' : response.data.name,
                'post_id' : response.data.id,
                'slug' : response.data.slug,
                'short_desc' : response.data.short_desc,
                'meta_title' : response.data.meta_title,
                'meta_desc' : response.data.meta_desc,
                'cate_id' : response.data.cate_id,
                'image_alt' : response.data.image_alt,
            });
            setLongDesc(response.data.long_desc);
            seteditImage(response.data.post_image);
          });
    }
    const handlechange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setAddForm(values => ({...values, [name]: value}));
        if(name==='name'){
            let newval =  value.toLowerCase()
            newval =  newval.trim();
            newval = newval.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();
            newval = newval.replace(/^\s+|\s+$/gm,'');
            var slug=newval.replace(/\s+/g, '-');
            // slug = slug.replace(/ /g, '-')
            // slug = slug.replace(/[^\w-]+/g, '');
            var check =slug.toLowerCase();
            //  .replace(/ /g, '-')
            //  .replace(/[^\w-]+/g, '');
            setAddForm(values => ({...values, ['slug']: check}));
        }
        
    }

    const handleImage = (e) => {
        var input = e.target;
        var image = document.getElementById('preview');
        if (input.files && input.files[0]) {
           var reader = new FileReader();
           reader.onload = function(e) {
              image.src = e.target.result;
           }
           reader.readAsDataURL(input.files[0]);
        }

        console.log('Files', e.target.files);
        setImage(e.target.files[0]);
    }
    

    useEffect(() => {
        setisLoading(true);
      axios.get(base_url+'/api/get-category').then((response) => {
        console.log("caetgory", response.data);
        setCategory(response.data);
        setisLoading(false);
      });

      setFormfield();
    
    }, []);
    let showContent;
    if(isLoading){
        showContent = <Loading />
    }else{
        showContent =  <div className="cm-card">
        <form onSubmit={handleSubmit} id="add-post-form">
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-45">
                        <label>Title</label>
                        <input type="text" className="form-control"  value={addForm.name} onChange={handlechange} name="name" placeholder="Enter Title" />
                    </div>
                    <div className="form-group cm-wd-45">
                        <label>Slug</label>
                        <input type="text"  className="form-control" value={addForm.slug} onChange={handlechange}  name="slug" placeholder="Enter Slug" />
                    </div>
                </div>
                <div className="form-group">
                    <label>Short Description</label>
                    <textarea className="form-control" rows={8} name="short_desc"  onChange={handlechange}  placeholder="Short Description.." defaultValue={addForm.short_desc}></textarea>
                </div>
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-45">
                        <label>Meta Title</label>
                        <input type="text" className="form-control" value={addForm.meta_title} onChange={handlechange} name="meta_title" placeholder="Enter Title" />
                    </div>
                    <div className="form-group cm-wd-45">
                        <label>Meta Description</label>
                        <input type="text"  className="form-control" value={addForm.meta_desc} onChange={handlechange} name="meta_desc" placeholder="Enter Slug" />
                    </div>
                </div>
                <div className="form-group">
                    <label>Category</label>
                    <select name="cate_id" value={addForm.cate_id} onChange={handlechange} className="form-control" >
                        <option value="">--Select Category --</option>
                        {category.length > 0 && category.map((row) => {
                            return (
                                <option value={row.id} key={row.id}>{row.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label>Image Alt Tag (Featured Image)</label>
                    <input type="text" className="form-control" value={addForm.image_alt} onChange={handlechange}  name="image_alt" />
                </div>
                <div className="form-group">
                    <label>Image </label>
                    <img id="preview" src={editimage}  style={{width: "150px"}} alt=""/>
                    <input type="file" className="form-control"  onChange={handleImage} name="post_image" />
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <CKEditor
        editor={ Editor }
        data={longdesc}
        onReady={ editor => {
            // You can store the "editor" and use when it is needed.
            console.log( 'Editor is ready to use!', editor );
        } }
        onChange={ ( event, editor ) => {
            const data = editor.getData();
            setLongDesc(data);
            console.log( { event, editor, data } );
        } }
        onBlur={ ( event, editor ) => {
            console.log( 'Blur.', editor );
        } }
        onFocus={ ( event, editor ) => {
            console.log( 'Focus.', editor );
        } }
        name="long_desc"
        rows={8}
    />
                {/* <textarea className="form-control"  onChange={handlechange} ></textarea> */}
            </div>
            <div className="form-group">
                <input type="submit" className="cm-prime-btn"/>
            </div>
        </form>
    </div>
    }
    // console.log("post description : ", addForm.short_desc);

   
    return(
        <div className="dashboard cm-flex-type-1">
            <Sidebar />
            <div className="cm-wd-80">
                <div className="boday-dashboard">
                    <h1>Edit Post</h1>
                    {errorMessage && errorMessage.map((row) => {
                        return (<div className="alert alert-danger">{row}</div>);
                    })}
                    {(showSuccess!=="")? <div className="alert alert-success">{showSuccess}</div> :''}
                    {showContent}
                </div>
            </div>
        </div>
    )
}

export default Editpost;