import React from "react";
import { FaCalendarAlt, FaRegComment } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { base_url } from "../../../utility/utility";
import axios from "axios";
import { Link } from "react-router-dom";
import { format } from "date-fns/esm";
import { parseISO } from "date-fns";
import Loading from "../../../component/Loading";

const WeekendBlog = () => {

   

    const getweekend = async() => {
        const url = base_url + '/api/get-post?type=weekentop';
        const response = await axios.get(url);
        return response.data
    }
    const  {isLoading, data} = useQuery({queryKey : ['getweekendblog'], queryFn : getweekend});

    return(
        <section className='cm-section'>
                <div className='cm-page-center'>
                    <div>
                        <h1 className='cm-heading cm-mb-30'>Weekend Top Blog</h1>
                    </div>
                    <div className='cm-flex-type-1 mob-flex-wrap cm-mob-wd-full'>
                        {isLoading ? <Loading /> : "" }
                        <div className='cm-wd-50 cm-mob-mb-20 cm-wd-full cm-flex cm-flex-wrap cm-weekend-box cm-position-relative'>
                            
                            <img src={(data?.length > 0) ? data[0]?.post_image : ''} alt='Weekend' />
                            <p>{(data?.length > 0) ? data[0]?.name : ''}</p>
                            <div className='cm-flex-type-1 weekend-image-section cm-mt-30'>
                                    <img src={(data?.length > 0) ? data[1]?.post_image : ''} className='' alt='Weekend' />
                                    <img src={(data?.length > 0) ? data[2]?.post_image : ''} className='' alt='Weekend' />
                            </div>
                        </div>
                        <div className='cm-wd-50 cm-wd-full cm-weekend-box cm-mob-wd-full'>
                            <div className='cm-weekendnewslist'>
                                <div className=''>
                                    <ul>
                                        {data?.map((row, index) => {
                                            if(index < 3) return '';
                                            return(
                                            <li className='cm-flex-type-2' key={row.id}>
                                                <div className='cm-wd-20 cm-mr-10 cm-mob-wd-40 cm-flex' >
                                                    <Link to={'post/' + row.slug}><img src={row.post_image} alt='rest' /></Link>
                                                </div>
                                                <div className='contentList'>
                                                    <div>
                                                        <Link to={'category/' + row.get_category.slug} className='category-btn'>{row.get_category.name}</Link>
                                                    </div>
                                                    <Link to={'post/' + row.slug} className="title">{row.name}</Link>
                                                    <ul>
                                                        <li><FaCalendarAlt /> {format(parseISO(row.created_at), 'dd-MM-yyyy')} </li>
                                                        <li ><FaRegComment /> {row.get_comments_count}</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        )})
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export  default WeekendBlog;