import axios from "axios";
import React, { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import UserSidebar from '../component/UserSidebar';
import {base_url} from '../utility/utility';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Loading from "../component/Loading";
import Sidebar from "../component/Sidebar";


const AddPost = () =>{
    const [category, setCategory] = useState({});
    const [errorMessage, seterrorMessage] = useState();
    const [successMesssage, setsuccessMessage] = useState();
    const [image, setImage] = useState('');
    const [longdesc, setLongDesc] = useState('');
    const [isLoading, setisLoading] = useState(true);
    const navigate = useNavigate();
    let userinfo = localStorage.getItem('userinfo');
    var token = JSON.parse(localStorage.getItem('token_user'));
    let datauser = JSON.parse(userinfo);

    // console.log("Token ::", token);

    const [addForm, setAddForm] = useState({
        'name':'',
        'slug' : '',
        'short_desc' : '',
        'meta_title' : '',
        'meta_desc' : '',
        'cate_id' : '',
        'image_alt' : ''
    });

    const clearformData = () => {
        setAddForm({ 
            'name':'',
            'slug' : '',
            'short_desc' : '',
            'meta_title' : '',
            'meta_desc' : '',
            'cate_id' : '',
            'image_alt' : ''
        });
        setImage('');
        setLongDesc('');

    }

    const handleSubmit = (e) => {
        window.scrollTo(0, 0);

        e.preventDefault();
        //setisLoading(true);
        let formdata = new FormData();
        formdata.append('name', addForm.name);
        formdata.append('slug', addForm.slug);
        formdata.append('short_desc', addForm.short_desc);
        formdata.append('long_desc', longdesc);
        formdata.append('meta_title', addForm.meta_title);
        formdata.append('meta_desc', addForm.meta_desc);
        formdata.append('cate_id', addForm.cate_id);
        formdata.append('user_id', datauser.id);
        formdata.append('post_image', image);
        axios.post(
            base_url+'/api/add-post', 
            formdata, 
            {
                headers: {
                    "Authorization" : `Bearer ${token}`,
                    "Content-Type": "multipart/form-data" 
                }
            }).then((response) => {
                if(response.data.status==='1'){
                    // console.log('response Submit Data', response.data);
                    document.getElementById("add-post-form").reset();
                    clearformData();
                    setsuccessMessage(response.data.msg);
                    seterrorMessage('');
                }else{
                    // console.log("error Message", response.data);
                    seterrorMessage(response.data.data);
                }
                
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if(userinfo===null){
            navigate('/')
        }
        setisLoading(false);
        axios.get(base_url+'/api/get-category').then((response) => {
            console.log("caetgory", response.data);
            setCategory(response.data);
            setisLoading(false);
        });
    }, []);


    const handlechange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setAddForm(values => ({...values, [name]: value}));
        if(name==='name'){
           let newval =  value.toLowerCase()
           newval =  newval.trim();
           newval = newval.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();
           newval = newval.replace(/^\s+|\s+$/gm,'');
            var slug=newval.replace(/\s+/g, '-');
            var check = slug.toLowerCase();
            setAddForm(values => ({...values, ['slug']: check}));
        }
    }

    const handleImage = (e) => {
        var input = e.target;
        var image = document.getElementById('preview');
        if (input.files && input.files[0]) {
           var reader = new FileReader();
           reader.onload = function(e) {
              image.src = e.target.result;
           }
           reader.readAsDataURL(input.files[0]);
        }

        console.log('Files', e.target.files);
        setImage(e.target.files[0]);
    }


    let showContent;
    if(isLoading){
        showContent = <Loading/>
    }else{
        showContent = <div className="cm-card" > 
            <form onSubmit={handleSubmit} id="add-post-form">
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-50">
                        <label>Title</label>
                        <input type="text" className="form-control"  value={addForm.name} onChange={handlechange} name="name" placeholder="Enter Title" />
                    </div>
                    <div className="form-group cm-wd-50">
                        <label>Slug</label>
                        <input type="text"  className="form-control" value={addForm.slug} onChange={handlechange} name="slug" placeholder="Enter Slug" />
                    </div>
                </div>
                <div className="form-group">
                    <label>Short Description</label>
                    <textarea className="form-control" rows={8}  name="short_desc"  onChange={handlechange}  placeholder="Short Description.."></textarea>
                </div>
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-50">
                        <label>Meta Title</label>
                        <input type="text" className="form-control" value={addForm.meta_title} onChange={handlechange} name="meta_title" placeholder="Enter Title" />
                    </div>
                    <div className="form-group cm-wd-50">
                        <label>Meta Description</label>
                        <input type="text" className="form-control" value={addForm.meta_desc} onChange={handlechange} name="meta_desc" placeholder="Enter Slug" />
                    </div>
                </div>
                <div className="cm-flex-type-1">
                    <div className="form-group cm-wd-50">
                        <label>Category</label>
                        <select name="cate_id" onChange={handlechange} className="form-control" >
                            <option value="">--Select Category --</option>
                            {category.length > 0 && category.map((row) => {
                                return (
                                    <option key={row.id} value={row.id}>{row.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="form-group cm-wd-50">
                        <label>Image Alt Tag (Featured Image)</label>
                        <input type="text" className="form-control" value={addForm.image_alt} onChange={handlechange}  name="image_alt" />
                    </div>
                </div>
                
                <div className="form-group">
                    <label>Image </label>
                    <input type="file" className="form-control" accept="image/*"  onChange={handleImage} name="post_image" />
                    <img id="preview" alt="Preview Image" style={{width:'100px'}} />
                </div>
                <div className="form-group">
                    <label>Description</label>
                    {/* <CKEditorComponent /> */}
                    <CKEditor
                        editor={ Editor }
                        data={longdesc}
                        onReady={ ( editor ) => {
                        console.log( "CKEditor5 React Component is ready to use!", editor );
                        } }
                        onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setLongDesc(data);
                        console.log( { event, editor, data } );
                        } }
                    />
                </div>
                <div className="form-group">
                    <input type="submit" className="cm-prime-btn"/>
                </div>
            </form>
        </div>
    }

    
    return(
        <React.Fragment>
        <div className="dashboard cm-flex-type-1">
            {/* Side Bar */}
            <Sidebar />
            <div className="cm-wd-50  cm-mlr-auto">
                <div className="boday-dashboard">
                    <div className="cm-flex-type-1">
                        <h3>Add Post</h3>
                        <Link to={`/list-post`} className="cm-prime-btn">Show Post</Link>
                    </div>
                   {errorMessage && errorMessage.map((row) => {
                        return (<div className="alert alert-danger">{row}</div>);
                    })}
                    {(successMesssage)? <div className="alert alert-success">{successMesssage}</div>:''}
                    {showContent}
                </div>
            </div>
            <div className="cm-wd-50 cm-mlr-auto">
                <div className="boday-dashboard cm-mt-40">
                <ol>
                    <li>
                    <p><strong>Relevance:</strong> Guesthubs should be relevant to the theme, topic, or niche of your website. Ensure that the content aligns with the interests and needs of your target audience.</p>
                    </li>
                    <li>
                    <p><strong>Originality:</strong> Require guest authors to submit original content that has not been published elsewhere. Plagiarism or duplicate content can damage your website's reputation and SEO ranking.</p>
                    </li>
                    <li>
                    <p><strong>Quality Standards:</strong> Set clear quality standards for Guesthubs, including grammar, spelling, formatting, and overall readability. Reject submissions that do not meet these standards.</p>
                    </li>
                    <li>
                    <p><strong>Word Count:</strong> Specify the minimum and maximum word count for Guesthubs to ensure they provide sufficient depth and value without being overly long or verbose.</p>
                    </li>
                    <li>
                    <p><strong>Formatting Guidelines:</strong> Provide guidelines for formatting Guesthubs, including headings, subheadings, bullet points, and images. This helps maintain consistency and readability across all published content.</p>
                    </li>
                    <li>
                    <p><strong>Author Bio and Links:</strong> Allow guest authors to include a brief author bio and relevant links to their website or social media profiles. However, limit the number of promotional links and ensure they are relevant and non-spammy.</p>
                    </li>
                    <li>
                    <p><strong>Editorial Rights:</strong> Reserve the right to edit Guesthubs for clarity, coherence, and compliance with your website's style and tone. Communicate any edits or changes to the guest author before publishing.</p>
                    </li>
                    <li>
                    <p><strong>Submission Process:</strong> Establish a clear submission process for Guesthubs, including where and how to submit, any required information or materials, and expected response times.</p>
                    </li>
                    <li>
                    <p><strong>Review and Approval:</strong> Review Guesthub submissions promptly and communicate clearly with authors regarding the status of their submissions. Provide feedback and guidance to improve rejected submissions whenever possible.</p>
                    </li>
                    <li>
                    <p><strong>Publication Schedule:</strong> Determine the publication schedule for Guesthubs, including specific dates or times when they will be published. Inform guest authors of the expected publication date in advance.</p>
                    </li>
                    <li>
                    <p><strong>Rights and Ownership:</strong> Clarify the rights and ownership of Guesthubs, including who retains copyright and whether the content can be republished elsewhere after appearing on your site.</p>
                    </li>
                    <li>
                    <p><strong>Ethical Standards:</strong> Ensure that Guesthubs adhere to ethical standards and avoid controversial or sensitive topics that may offend or alienate your audience.</p>
                    </li>
                    </ol>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default AddPost;