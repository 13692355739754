import React, { useState, useEffect } from "react";
import axios from "axios";
import {base_url} from '../utility/utility';
import { Link } from "react-router-dom";
import '../css/admin.css';
import Loading from "../component/Loading";
import UserSidebar from '../component/UserSidebar';
import Sidebar from "../component/Sidebar";
const ListPost = () =>{
    const [listpost, setlistPost] = useState({});
    let userinfo = localStorage.getItem('userinfo');
    let datauser = JSON.parse(userinfo);
    useEffect(() => {
        const tokenss = JSON.parse(localStorage.getItem('token_user'));
        
        axios.get(base_url+'/api/list-post/'+datauser.id, {
            headers: {
                "Authorization" : `Bearer ${tokenss}`
            }
        }).then((response) => {
          console.log("postlist", response.data);
          setlistPost(response.data);
        });
      }, []);

      if(listpost.length > 0){
        return(
            <div className="dashboard cm-flex-type-1">
                <Sidebar />
                <div className="cm-wd-85 cm-m-auto boday-dashboard">
                    <h3>Post List</h3>
                    <div className="cm-flex-type-3 cm-flex-wrap">
                        {(listpost.length > 0) && listpost.map((row) => {
                            let Edit = (row.status=='0')? <Link to={'/edit-post/'+row.id} className="cm-success-sm-btn">Edit</Link> : '';
                            let contentRemark =  (row.remark!==null) ? <div className="remark-topic"><p>Remark</p><p>{row.remark}</p></div>: '';
                            return(
                                <div className="box-list-post" key={row.id}>
                                    <img src={row.post_image} alt="" />
                                    <p>{row.name}</p>
                                    {contentRemark}
                                    <div>
                                        <a href="" className="cm-prime-sm-btn">{(row.status=='1')?'Approve':'Pending'}</a>&nbsp;
                                        {Edit}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
      }else{
            return (
                <div className="dashboard cm-flex-type-1">
                <div className="sidebar cm-wd-20">
                    <UserSidebar />
                </div>
                <div className="cm-wd-75">
                    <h1>Post List Detail</h1>
                    {(listpost.length==0) ? <div>There is no data</div>  : <Loading />}
                </div>
            </div>
            );
      }


   
}

export default ListPost;