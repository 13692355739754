import React from 'react';
import Navbar from '../../component/Navbar';
import Footer from '../../component/Footer';
import Helmet from 'react-helmet';
 const  About = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>About | Guesthub site free use all users</title>
            <link rel="canonical" href="" />
        </Helmet>
        <Navbar title = "Logo" />
        <div className='cm-position-relative herosection'>
            <img src='/images/photo-galleery1-bg.jpg' alt='' />
            <div className='cm-position-absolute hero-section-inner'>
                <h3>About Us</h3>
            </div>
        </div>
        <section className='cm-section'>
            <div className='cm-page-center'>
                <div className='about_area'>
                    <h1>About Us: Welcome to Our Guesthub Community</h1>
                    <p>AtGuestHubse, we're more than just a platform for Guesthubs; we're a thriving community of passionate writers, thought leaders, and avid readers who believe in the power of sharing knowledge and ideas. Our journey began with a simple vision: to create a space where diverse voices can come together to inspire, educate, and connect.</p>
                    <h2>Our Mission: Empowering Voices, Amplifying Ideas</h2>
                    <p>Our mission is to empower individuals from all walks of life to have their voices heard. We believe that everyone has a unique perspective and valuable insights to share. Through our Guesthub platform, we provide a welcoming and inclusive environment where writers can express themselves freely, fostering a rich exchange of ideas that transcends boundaries.</p>
                    <h2>Why Choose GuestHubse?</h2>
                    <p>1. A Platform for Connection: We understand the importance of meaningful connections in today's digital age. Our platform isn't just about publishing content; it's about forming connections, sparking conversations, and building relationships with like-minded individuals.</p>
                    <p>2. <strong>Diverse Topics, Varied Perspectives: </strong>Our community covers an array of topics, from technology and lifestyle to health and finance. This diversity of subjects brings together a wide range of perspectives, encouraging readers to explore new horizons and expand their knowledge.</p>
                    <p>3. <strong>Quality and Authenticity:</strong> We are committed to maintaining high standards of quality and authenticity. Each Guesthub undergoes a thorough review process to ensure accurate information, engaging writing, and valuable insights.</p>
                    <p>4. <strong>Supportive Community:</strong> Our members are at the heart of our community. From aspiring writers to seasoned experts, everyone is encouraged to participate, ask questions, and provide constructive feedback. We believe in fostering an environment of mutual respect and learning.</p>
                    <p>5. <strong>A Gateway to Opportunity:</strong> Guesthubing with us is more than just sharing your thoughts. It's a gateway to reaching a broader audience, establishing your expertise, and creating a positive impact in your field of interest.</p>
                    <div className='cm-flex-type-1'>
                        <div className='cm-wd-40 cm-mr-20 about-image'>
                            <img src='/images/About-img.jpg' className="cm-rounded-md cm-wd-80" alt=''/>
                        </div>
                        <div className='cm-wd-60 about-content'>
                            <h3>Our Vision: Writing the Future Together</h3>
                            <p>As we look ahead, our vision remains clear: to continue facilitating a space where knowledge knows no bounds and ideas have the power to transform lives. We aspire to be a driving force behind positive change, one Guesthub at a time.</p>
                            <h3>Join Our Community: Share, Connect, Inspire</h3>
                            <p>Whether you're an experienced writer or someone with a fresh perspective,GuestHubse invites you to be part of our vibrant community. Join us in celebrating the beauty of diverse thoughts, the strength of collective wisdom, and the thrill of learning from one another.</p>
                            <p>Thank you for being a part of this journey. Together, we'll create a world where ideas flow freely, and voices resound far and wide.</p>
                        </div>
                    </div>
               
                </div>
            </div>
        </section>
        <Footer />
        </>
    );
}

export default About;