import React, {useState} from "react";

const Myaccount = () => {
    const  [users, login] = useState();
    return (
        <div>
            <h1>My Profile Page</h1>
        </div>
    );
}

export default Myaccount;