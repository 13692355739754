import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../component/Navbar";
import Footer from "../../component/Footer";
import { base_url } from "../../utility/utility";
import axios from "axios";
import { BusinessTime } from "./BusinessTime";
import "../../css/business.css";
import { useQuery } from "@tanstack/react-query";


export const BusinessDetail = () => {
    const param = useParams();
    const getDetilBusiness = async() => {
        const respond = await axios.get(base_url+'/api/get-business-detail/'+param.slugBusiness);
        return respond.data;
    }
    const {isLoading, data: business} = useQuery({queryKey : ['businessDetail'], queryFn: getDetilBusiness});

    return(
        <>
          <Navbar />
          <div className='cm-position-relative herosection'>
            <img src='/images/photo-galleery1-bg.jpg' alt='' />
            <div className='cm-position-absolute hero-section-inner'>
                <h3>{business?.compName}</h3>
            </div>
        </div>
        <div className="cm-section">
            <div className="cm-page-center cm-mt-50">
                <div className="cm-flex-type-1">
                    <div className="cm-wd-70 businessdetail-desc">
                        <div className="cm-flex">
                            <img src={business?.compLogo} style={{width:'100px'}}  width="100px"/>
                            <h1>{business?.compName}</h1>
                        </div>
                        <p> Country:  <strong>{business?.country}</strong> </p>
                        <p> No of employee:  <strong>{business?.noEmployee}</strong> </p>
                        <p> Compnay Found:  <strong>{business?.compFoundOn}</strong> </p>
                        <p> Website :  <strong>{business?.websiteUrl}</strong> </p>
                        <p> Address :  <strong>{business?.compAddress}</strong> </p>
                        <div className="short-description-comp">
                            <p>{business?.short_desc}</p>
                        </div>
                        <div className="business-longdesc">
                            {business?.long_desc}
                        </div>
                    </div>
                    <div className="business-hours cm-wd-25 businessdetail-timetable">
                        <h3>Opening Business Time</h3>
                        {!!business?.businessWorkingDays ? <BusinessTime business={business?.businessWorkingDays} /> : ''}
                    </div>
                </div>
                <div className="companygallery">

                </div>
            </div>
            
        </div>
          
          <Footer />  
        </>
    );
}