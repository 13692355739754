import React from "react";
import UserSidebar from "./UserSidebar";



const Sidebar = () => {
    return(
        <div className="sidebar cm-wd-10">
            <UserSidebar />
        </div>
    )
}

export default Sidebar;

