import React, {useEffect, useState} from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { base_url } from "../utility/utility";
// import "./styles.css";

// import required modules
import { Pagination } from "swiper";
import { useQuery } from "@tanstack/react-query";
import Loading from "./Loading";

export default function Footergallery() {

  const getFooterimage = async() => {
    const url = base_url+'/api/feature_images';
    const respond = await axios.get(url);
    return respond.data;
}

  const {isLoading, data} = useQuery({queryKey: ['footerimage'], queryFn: getFooterimage});

  return (
    <>
    <section className="section-gallery">
      <div className="cm-page-center cm-mb-30">
        <h3 className="cm-heading">Featured Images</h3>
      </div>
      {isLoading ? <Loading /> : "" }
    <Swiper
        slidesPerView={6}
        // spaceBetween={}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 80 },
          480: { slidesPerView: 2, spaceBetween: 10 },
          1024: { slidesPerView: 6, spaceBetween: 20 }
        }}
        className="mySwiper"
      >
        {data?.map((row, index) =>(
            <SwiperSlide key={index}>
              <img src={row.post_image} alt="gallery"/>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </section>
      
    </>
  );
}
