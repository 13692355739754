import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineArticle, MdAddBusiness, MdSettings, MdLogout } from "react-icons/md";
import { FaListAlt, FaUserCircle } from "react-icons/fa";

const UserSidebar = () => {
    const [activeMenu, setactiveMenu] = useState('/dashboard');
    let pathval = useLocation();
    useEffect(() => {
        let splitval = pathval.pathname.split("/");
        setactiveMenu(splitval[1]);
    },[])

    return (
        <div className="sidebar-nav">
            <ul>
                <li className={`${(activeMenu==='dashboard')?'active':''}`}>  
                    <Link to="/dashboard"><RxDashboard /> Dashboard</Link>
                </li>
                <li className={`${(activeMenu==='add-post')?'active':''}`}>
                    <Link to="/add-post"><MdOutlineArticle /> Add Post</Link>
                </li>
                <li className={`${(activeMenu==='list-post'||activeMenu==='edit-post')?'active':''}`}>
                    <Link to="/list-post"><FaListAlt /> List Post</Link>
                </li>
                <li className={`${(activeMenu==='manage-business')?'active':''}`}>
                    <Link to="/manage-business"><MdAddBusiness /> Buesiness Listing</Link>
                </li>
                <li className={`${(activeMenu==='profile')?'active':''}`}>
                    <Link to="/profile"><FaUserCircle /> Profile</Link>
                </li>
                <li className={`${(activeMenu==='setting')?'active':''}`}>
                    <Link to="/setting"><MdSettings /> Setting</Link>
                </li>
                <li>
                    <Link to="/logout"><MdLogout /> Logout</Link>
                </li>
            </ul>
        </div>
    );
}

export default UserSidebar;