import React, {useState} from 'react';
import {Link, NavLink } from "react-router-dom";

import { FiChevronDown, FiSearch, FiX} from "react-icons/fi";
import {RxHamburgerMenu} from "react-icons/rx";
import axios from 'axios';
import SearchComponent from './SearchComponent';

import { base_url } from '../utility/utility';

import '../css/responsive.css';
import { useQuery } from '@tanstack/react-query';
import Login from '../pages/front/component/Login';
import Register from '../pages/front/component/Register';
import Forgot from '../pages/front/component/Forgot';

const Navbar = () => {
  const [popup, setPopup] = useState(false);
  const [opentype, setOpentype] = useState();
  const [activeStatus, setActiveStatus] = useState('');
  const [menuactive, setMenuActive] = useState(false);

  const categories = async() => {
    const url = base_url+'/api/get-category';
    const respond = await axios.get(url);
    return respond.data;
  }

  const {isLoading, data: category} = useQuery({queryKey: ['category'], queryFn: categories});

  function checkAuthlogin(){
    let userinfo  = localStorage.getItem('userinfo');
    let userinfget = JSON.parse(userinfo);
    if(userinfget!==null){
      return userinfget;
    }
    return false;
  } 
  let LoginPopup;

  if(checkAuthlogin()){
   let user =  checkAuthlogin();
  //  console.log("All User", user);
    LoginPopup = <Link className="cm-prime-btn" to="/dashboard"> My Account 👋 {user.fname} </Link>
  }else{
    LoginPopup = <><Link className="cm-prime-btn" onClick={() => handleChange(true, 'login')}> Login </Link>  &nbsp;
                  <Link className="cm-prime-btn" onClick={() => handleChange(true, 'register')}> Register </Link></>
  }

  function handleChange(newValue, popup) {
    console.log("new value", newValue, popup);
    setPopup(true);
    setOpentype(popup);
  }

  const searchClick = (e) => {
    console.log("Click On Search", e);
    setActiveStatus(e);
  }



  const activeMenu = (val) => {
      (menuactive===false) ? setMenuActive(true) : setMenuActive(false);
  }


    return (
      <>
        <SearchComponent status={activeStatus} searchClick={searchClick} />
        <header>
          <div className="cm-page-center">
            <nav className="topnavBar">
              <div className="cm-flex-type-1 cm-deco-none mob-flex-wrap">
                <div className='logo'>
                  <Link  to="/"> <img src='/newlogo.png' /> </Link>
                </div>
                <div className={`cm-navbar-menu ${menuactive===true ? 'active' : ''}`}>
                  <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : 'inactive')} ><span>Home</span></NavLink>
                  <NavLink to="/about" className={({ isActive }) => (isActive ? 'active' : 'inactive')} ><span>About</span></NavLink>
                  {/* <Link  to="/"> Home </Link> */}
                  <div className="dropdown">
                    <NavLink ><span>Categories <FiChevronDown /></span></NavLink>
                    <div className="dropdown-content">
                      { category  && category.map((cate) => {
                          return(
                            <Link  to={`/category/${cate.slug}`} key={cate.id}> {cate.name}</Link>
                          );
                        })
                      }
                      
                    </div>
                  </div>
                  <NavLink to="/post-guidlines" className={({ isActive }) => (isActive ? 'active' : 'inactive')} ><span>Post Guidline </span></NavLink>
                  <NavLink to="/business-listing" className={({ isActive }) => (isActive ? 'active' : 'inactive')} ><span>Buesiness Listing  </span></NavLink>
                  <NavLink to="/contact-us" className={({ isActive }) => (isActive ? 'active' : 'inactive')} ><span>Contact Us </span></NavLink>
                  {/* <NavLink to="/contact-us" className={({ isActive }) => (isActive ? 'active' : 'inactive')} ><span>Contact Us </span></NavLink> */}
                </div>
                <div className="login-search">
                  <Link onClick={() => searchClick('active')}> <FiSearch className='searchIcon' /> </Link>
                      {LoginPopup}
                </div>
                <div className='hamburgerMenu'>
                  <a href='#' onClick={() => activeMenu()}><RxHamburgerMenu /></a>
                </div>
              </div>
            </nav>
          </div>
        </header>
        {
          opentype==='login' && popup==true ?
          <Login setPopup={setPopup} setOpentype={setOpentype} /> :
          opentype==='register' && popup==true ? 
          <Register setPopup={setPopup} setOpentype={setOpentype} /> :
          opentype==='forgot' && popup==true ? 
          <Forgot setPopup={setPopup} setOpentype={setOpentype} /> : ""
        }
      </>
    );
}


export default Navbar;