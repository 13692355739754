
// Development Url
const base_url = 'https://admin.guesthubse.com';
const URL = 'https://guesthubse.com';

const API_KEY = 'http://localhost:8000';
// Production URL
const live_url = 'https://admin.guesthubse.com';
export {
    base_url,
    API_KEY,
    URL,
    live_url
}