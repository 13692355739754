import axios from "axios";
import React, { useState} from "react";
import '../css/dashboard.css';
import {base_url} from '../utility/utility';
import Sidebar from "../component/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Loading from "../component/Loading";

const Profile = () =>{
    const [profile, setProfile] = useState({});
    const [profileimage, seteditProfile] = useState();

    let userinfo = localStorage.getItem('userinfo');
    var token = JSON.parse(localStorage.getItem('token_user'));
    var user = JSON.parse(userinfo)

    const {data, isLoading} = useQuery({queryKey:['getProfile'], queryFn: () => fetchProfile()});

    const queryClient  = useQueryClient();

    const {mutate} = useMutation( {mutationFn: (data) =>  updateProfile(data), 
        onSuccess: (data) => {
            const message = "success";
            if(data?.status===true){
                toast.success(data?.msg);
            }
            // toast.success("Update Successfully Data!!");
        },
        onError: () => {
            console.log("Something went wrong in code");
        },
        onSettled: () => {
            queryClient.invalidateQueries('create')
        }
    });


    const updateProfile = async(data) => {
        console.log("postdata", data);
        let url =  base_url+'/api/update-profile';
        const {data :response} = await axios.post(url, data, {headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer  ${token}`
        }});
        return response;
    }


    const handletosubmit = (e) => {
        e.preventDefault();
        let data = new FormData();
        data.append('fname', profile.fname);
        data.append('lname', profile.lname);
        data.append('email', profile.email);
        data.append('mobile', profile.mobile);
        data.append('facebook_link', profile.facebook_link);
        data.append('instagram_link', profile.instagram_link);
        data.append('linkedIn_link', profile.linkedIn_link);
        data.append('pintreset_link', profile.pintreset_link);
        data.append('twitter_link', profile.twitter_link);
        data.append('youtube_link', profile.youtube_link);
        data.append('user_bio', profile.user_bio);
        if(profileimage!=undefined){
            data.append('profile_image', profileimage);
        }
        let responseResult = mutate(data);
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setProfile(values => ({...values, [name]: value}));
    }

    const handleImage = (e) => {
        seteditProfile(e.target.files[0]);
    }

    const  fetchProfile  = async() => {
        let url = base_url+'/api/profile?user_id='+user.id;

        const respose  =  await axios.get(url,{
            headers: {
            "Authorization" : `Bearer ${token}`
            }
        });
        setProfile(respose?.data);
        return respose?.data;
    }

    return(
        <div className="dashboard cm-flex-type-1">
            <Sidebar />
            <div className="cm-wd-60 cm-mlr-auto">
                <div className="boday-dashboard">
                    <h3>Profile Information</h3>
                    {isLoading ? <Loading /> :"" }
                    <div className="profile-page cm-card">
                <form onSubmit={handletosubmit}>
                    <div className="form-group">
                        <div className="profile">
                        <label htmlFor="file-upload" className="profile-file-upload">
                            <img src={profile?.profile_img} />
                        </label>
                        <input id="file-upload" onChange={handleImage} style={{display : "none"}} type="file"/>
                        </div>
                    </div>
                    <div className="cm-flex-type-1">
                        <div className="form-group cm-wd-50">
                            <label>First Name</label>
                            <input type="text" name="fname" onChange={handleChange} value={profile.fname} autoComplete="off" className="form-control" />
                        </div>
                        <div className="form-group cm-wd-50">
                            <label>Last Name</label>
                            <input type="text" name="lname" onChange={handleChange} value={profile.lname} autoComplete="off" className="form-control" />
                        </div> 
                    </div>
                    <div className="cm-flex-type-1">
                        <div className="form-group cm-wd-50">
                            <label>Email</label>
                            <input type="email" name="email" readOnly onChange={handleChange} value={profile.email} autoComplete="off" className="form-control" />
                        </div>
                        <div className="form-group cm-wd-50">
                            <label>Mobile</label>
                            <input type="text" name="mobile" onChange={handleChange} value={profile.mobile} autoComplete="off" className="form-control" />
                        </div>
                    </div>
                    <h3>Bio Data</h3>
                    <div className="cm-flex-type-1">
                        <div className="form-group cm-wd-100">
                            <textarea type="text" name="user_bio" onChange={handleChange} value={profile.user_bio} rows={8} className="form-control" />
                        </div>
                    </div>
                    <h3>Social Media Links</h3>
                    <div className="cm-flex-type-1">
                        <div className="form-group cm-wd-50">
                            <label>Facebook</label>
                            <input type="text"  onChange={handleChange} value={profile.facebook_link} name="facebook_link" className="form-control" />
                        </div>
                        <div className="form-group cm-wd-50">
                            <label>Instagram</label>
                            <input type="text"  onChange={handleChange}  value={profile.instagram_link} name="instagram_link" className="form-control" />
                        </div>
                    </div>
                    <div className="cm-flex-type-1">
                        <div className="form-group cm-wd-50">
                            <label>Pintreset</label>
                            <input type="text"  onChange={handleChange}   value={profile.pintreset_link} name="pintreset_link" className="form-control" />
                        </div>
                        <div className="form-group cm-wd-50">
                            <label>Youtube</label>
                            <input type="text"  onChange={handleChange}  value={profile.youtube_link} name="youtube_link"  className="form-control" />
                        </div>
                    </div>
                    <div className="cm-flex-type-1">
                        <div className="form-group cm-wd-50">
                            <label>Twitter</label>
                            <input type="text"  onChange={handleChange}  value={profile.twitter_link} name="twitter_link" className="form-control" />
                        </div>
                        <div className="form-group cm-wd-50">
                            <label>LinkedIn</label>
                            <input type="text"  onChange={handleChange}  value={profile.linkedin_link} name="linkedin_link"  className="form-control" />
                        </div>
                    </div>
                    <div className="cm-flex-type-1">
                        <input type="submit" name="submit" className="cm-prime-btn" value="Save" />
                    </div>
                </form>
            </div>

                </div>
            </div>
        </div>
    );
}

export default Profile;