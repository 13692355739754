import React from "react";
import { icons } from "react-icons";
import { FaUserAlt, FaCalendarAlt, FaRegComment } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { base_url } from "../../../utility/utility";
import Loading from "../../../component/Loading";



const RecentBlog = () => {

    const getRecentBlog = async() => {
        const url = base_url + '/api/get-post?type=recent';
        const respond = await axios.get(url);
        return respond.data;
    }

    const {isLoading, data} = useQuery({queryKey: ['getRecentblog'], queryFn: getRecentBlog});

    return (
        <>
            <section className="cm-section">
                <div className="cm-page-center">
                    <h1 className="cm-heading cm-mb-30">Recent Blog</h1>
                    <div className="cm-flex cm-trading-news cm-flex-type-2">
                        {isLoading ? <Loading /> : "" }

                        <Swiper
                            navigation={true} modules={[Navigation]}
                            slidesPerView={4}
                            spaceBetween={10}
                            className="mySwiper"
                            breakpoints={{
                                320: { slidesPerView: 1, spaceBetween: 80 },
                                480: { slidesPerView: 1, spaceBetween: 50 },
                                768: { slidesPerView: 3, spaceBetween: 50 },
                            }}
                        >
                            {data?.map((row, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="" key={row.id}>
                                            <div className="cm-trading-content">
                                                <Link to={'post/' + row.slug}>
                                                    <img src={row.post_image} alt="Test" />
                                                </Link>
                                                <div>
                                                    <Link to={'post/' + row.slug}>{row.name}</Link>
                                                    <div className='cm-flex-type-1 cm-mt-20'>
                                                        <span><FaUserAlt /> {row.get_user.fname} </span>
                                                        <span><FaCalendarAlt /> {format(parseISO(row.created_at), 'dd-MM-yyyy')}</span>
                                                        <span><FaRegComment /> {row.get_comments_count}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RecentBlog;