import React from "react";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import axios from "axios";
import { base_url } from "../utility/utility";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Loading from "./Loading";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// 

const Category = () => {
    
    const categories = async() => {
        const url = base_url+'/api/get-category';
        const respond = await axios.get(url);
        return respond.data;
    }

    const {isLoading, data} = useQuery({queryKey: ['category'], queryFn: categories});

    return (
        <>
        {isLoading ? <Loading />: ''}
        <Swiper
        slidesPerView={6}
        // spaceBetween={}
        pagination={{
            type: 'fraction',
        }}
        modules={[Pagination]}
        breakpoints={{
            320: { slidesPerView: 2, spaceBetween: 0 },
            480: { slidesPerView: 3, spaceBetween: 10 },
            768: { slidesPerView: 4, spaceBetween: 10 },
            1024: { slidesPerView: 8, spaceBetween: 10 },
        }}

        className="mySwiper">       
        {data?.map((row, index) => (
            <SwiperSlide key={index}>
                <div className='categor-slide'>
                    <Link to={'/category/'+row.slug} ><img src={row.cat_image} /></Link>
                    <p>{row.name}</p>
                </div>
            </SwiperSlide>
        ))}             
    </Swiper>
    </>
    );
}


export default Category;