import React, { useState } from "react";
import { base_url } from "../../../utility/utility";
import axios from "axios";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";

const Register = ({setPopup, setOpentype}) => {
    const [buttondis, setbuttonDisble] = useState(false);
    const[inputValue, setInputValue] = useState({
        'fname': "",
        'lname': "",
        'email': "",
        'password': "",
        'cpassword': "",
        'mobile': ""
      });
    const handleInputChange = (e) => { 
        const name = e.target.name;
        const value = e.target.value;
        setInputValue(values => ({...values, [name]: value}));
      }
      
      const handleSubmit = (event) => {
        event.preventDefault();
        setbuttonDisble(true);
        let url = base_url+'/api/user-register';
        axios.post(url,inputValue).then((response) =>{
          if(response.data.status==='0'){
              setbuttonDisble(false);
              response.data.data.forEach(element => {
                  toast.error(element);
              });
          }else{
            toast.success('Register successfully.');
            setbuttonDisble(false);
            setPopup(false)
            setInputValue({
              'fname': "",
              'lname': "",
              'email': "",
              'password': "",
              'cpassword': "",
              'mobile': ""
            });
          } 
        } );
    }
    return ( <div className="modal">
    <div className='modal-overlay'></div>
    <div className="modal-content">
    <span onClick={() => setPopup(false)} className="close-modal"><FiX /></span>
      <div className="modal-body">
        <h3 className="cm-text-center text-white">Register User</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="text-white">First Name</label>
            <input type="text" name="fname" className="form-control"  value={inputValue.fname || ""} onChange={handleInputChange} placeholder="Enter first name" />
            
          </div>
          <div className="form-group">
            <label className="text-white">Last Name</label>
            <input type="text" name="lname" value={inputValue.lname || ""} onChange={handleInputChange} className="form-control"  placeholder="Enter last name" />
            
          </div>
          <div className="form-group">
            <label className="text-white">Email</label>
            <input type="text" name="email" value={inputValue.email || ""} onChange={handleInputChange} className="form-control"  placeholder="Enter username" />
            
          </div>
          <div className="form-group">
            <label className="text-white">Password</label>
            <input type="password" className="form-control" value={inputValue.password || ""} onChange={handleInputChange} placeholder="Enter password" name="password" />
            
          </div>
          <div className="form-group">
            <label className="text-white">Confirm Password</label>
            <input type="password" className="form-control" value={inputValue.cpassword || ""} onChange={handleInputChange} placeholder="Enter Confirm password" name="cpassword" />
            
          </div>
          <div className="form-group">
            <label className="text-white">Mobile</label>
            <input type="text" name="mobile" value={inputValue.mobile || ""} onChange={handleInputChange} className="form-control"  placeholder="Enter Mobile No"/>
            
          </div>
          <div className="form-group">
            <input type="submit" name="Login" className="cm-prime-btn" disabled={buttondis} value={buttondis?'load...': 'Register'} />
          </div>
        </form>
        <div className="cm-flex-type-1">
          <span onClick={() => setOpentype('forgot')} className="cursor text-white">Forgot Password ?</span>
          <span onClick={() => setOpentype('login')} className="cursor text-white">Sign In ?</span>
        </div>
      </div>
      <div className="modal-footer"></div>
    </div>
  </div>);
}

export default Register;