import React from "react";
import { Link } from "react-router-dom";


const Pagination = ({links, currentPage, category}) =>{
    // console.log("Category",category);
    // console.log("pagiantion",perPage);
    // let loop = total/perPage;
    // let paginate = [];
    
    console.log('Page Links',links);

    if(links!==undefined && links.length <= 3){
        return ;
    }



    // let maxVal=5;

    const getUrlvalue = (urldata) =>{
        // console.log("URL", urldata);
        if(urldata!==null){
            const params = new URL(urldata).searchParams;
            return params.get('page'); // "1"
        }
        
    }

    let params, page;
    return (
        <ul className="pagination">
        {links && links.map((row, index) => {
            page = getUrlvalue(row.url);
            // console.log("PAGES", page);
            if(row.url===null){
                return <span dangerouslySetInnerHTML={{__html: `${row.label}`}} key={index}></span>
            }
            return <Link key={index} to={`/category/${category.toLowerCase()}/page/${page}`} className={`${(currentPage==page) ? `active` : ``}`} dangerouslySetInnerHTML={{__html: `${row.label}`}} ></Link>
        })}
        </ul>
    );
}

export default Pagination;