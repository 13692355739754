import React, { useState } from "react";
import { FaTimes, FaSearch} from "react-icons/fa";
import { useNavigate,createSearchParams } from "react-router-dom";

const SearchComponent = (props) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const searchType = (value) => {
        setSearchText(value);
    }

    const params = { search: searchText};
    const goToPosts = (e) => {
        e.preventDefault();
        props.searchClick(false);
        navigate({pathname: '/search', search: `?${createSearchParams(params)}`});
    }

    return (
        <>
        <div className="SearchModal" style={{display: props.status=='active' ? "block" : "none"}}>
            <div className="search-headre" onClick={() => props.searchClick(false)}>
            <FaTimes />
            </div>
            <div className="search-content">
                <div className="search-body">
                    <form onSubmit={goToPosts}>
                    <div className="cm-flex">
                        <input type="text" onChange={(e) => searchType(e.target.value)} name="search" />
                        <button type="submit"><FaSearch /></button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    );
}

export default SearchComponent;