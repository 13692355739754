import React, { useState } from "react";
import { base_url } from "../../../utility/utility";
import axios from "axios";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";

const Login  = ({setPopup, setOpentype}) => {
    const [buttondis, setbuttonDisble] = useState(false);
    const[inputlogin, setloginInput] = useState({
        'email': '',
        'password' : ''
    });

    const handleInputChange = (e) => { 
        const name = e.target.name;
        const value = e.target.value;
        setloginInput(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("input", inputlogin);
        setbuttonDisble(true);
        let url = base_url+'/api/user-login';
        axios.post(url,inputlogin).then((response) =>{
          if(response.data.status==='1'){
            setloginInput({
              'email':'',
              'password':''
            });
            localStorage.setItem('userinfo', JSON.stringify(response.data.data));
            localStorage.setItem('token_user', JSON.stringify(response.data.token));
            setbuttonDisble(false);
            setPopup(false)
            toast.success("Login Successfully!");
          }else{
            console.log("Error Data::", response.data);
            toast.warning(response.data?.message);
            setbuttonDisble(false);
          }
        });
    }


    return (<div className="modal">
    <div className='modal-overlay'></div>
    <div className="modal-content">
    <span  className="close-modal" onClick={() => setPopup(false)}><FiX /></span>
    <div className="modal-head"></div>
    <div className="modal-body">
      <h3 className="cm-text-center text-white">Login Form</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input type="email" name="email" value={inputlogin.email} onChange={handleInputChange}  className="form-control"  placeholder="Enter username"/>
        </div>
        <div className="form-group">
          <input type="password" className="form-control" value={inputlogin.password} onChange={handleInputChange} placeholder="Enter password" name="password" />
        </div>
        <div className="form-group">
          <input type="submit" name="Login" disabled={buttondis} className="cm-prime-btn" value={`${buttondis ? 'Login...':'Login' }`} />
        </div>
      </form>
      <div className="cm-flex-type-1">
        <span onClick={() => setOpentype('forgot')} className="cursor text-white"> Forgot Password ?</span>
        <span  onClick={() => setOpentype('register')} className="cursor text-white"> Sign Up ?</span>
      </div>
    </div>
    <div className="modal-footer"></div>
  </div>
</div>)
}

export default Login;