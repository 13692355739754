import React, { useState, useEffect } from "react";
import axios from "axios";
import {base_url} from '../../utility/utility';
import { Link } from "react-router-dom";
import '../../css/admin.css';
import Loading from "../../component/Loading";

import UserSidebar from "../../component/UserSidebar";
import Sidebar from "../../component/Sidebar";
import { useQuery } from "@tanstack/react-query";
const BusinessListmanage = () => {
    const [listpost, setlistPost] = useState({});
    let userinfo = localStorage.getItem('userinfo');
    let datauser = JSON.parse(userinfo);
    const tokenss = JSON.parse(localStorage.getItem('token_user'));
    const getBusinessList = async() => {
        const respond = await axios.get(base_url+'/api/get-business-list?user_id='+datauser.id, {
            headers: {
                "Authorization" : `Bearer ${tokenss}`
            }
        });
        return respond.data;
    }
    const {data, isLoading} = useQuery({queryKey: ['businesslist'], queryFn : getBusinessList});

    return(
        <div className="dashboard cm-flex-type-1">
            <Sidebar />
            <div className="cm-wd-85 cm-mlr-auto boday-dashboard">
                <div className="cm-flex-type-1">
                    <h2>Business List</h2>
                    <Link  to={'/add-business-list'} className="cm-prime-sm-btn">Add Business</Link>
                </div>
                
                <div className="cm-flex-type-1 cm-flex-wrap">
                    {isLoading ? <Loading /> : ""}
                    {data?.map((row) => {
                        let Edit = (row.status=='0')? <Link to={'/edit-post/'+row.id} className="cm-success-sm-btn">Edit</Link> : '';
                        return(
                            <div className="box-list-business cm-flex" key={row.id}>
                                <img src={row.compLogo} alt="" />
                                <div>
                                    <p>{row.compName}</p>
                                    <p>Remark</p>
                                    <p>{row.phoneNumber}</p>
                                    <a href="" className="cm-prime-sm-btn">{(row.status=='1') ? 'Approve':'Pending'}</a>&nbsp;
                                    {Edit}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
   
}

export default BusinessListmanage;